import React, { Component } from 'react';
import { HashLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import video from '../../media/frontpage.mp4';

class Media extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
    this.myRef = React.createRef();
  }
  componentDidMount() {
    this.getReadyState();
    this.interval = setInterval(this.getReadyState, 1000);
  }

  componentWillUnmount() {
    // Clear the interval right before component unmount
    clearInterval(this.interval);
  }

  getReadyState = () => {
    if (this.myRef.current.readyState > 3) {
      this.setState({
        loading: false
      });
    }
  };

  render() {
    return (
      <div className='media-container'>
        <div
          className='video-overlay'
          style={{
            backgroundColor: this.state.loading
              ? 'rgba(232, 232, 232, 0.8)'
              : 'rgba(252, 231, 231, 0.2)'
          }}
        >
          <HashLoader
            sizeUnit={'px'}
            size={40}
            color={'#000000'}
            loading={this.state.loading}
          />
        </div>
        <video
          src={video}
          ref={this.myRef}
          className='media'
          type='video/mp4'
          style={{
            filter: this.state.loading ? 'blur(30px)' : 'blur(0px)',
            WebKitFilter: this.state.loading ? 'blur(30px)' : 'blur(0px)',
            transition: 'filter 1s linear'
          }}
          autoPlay
          muted
          loop
        />
        <a
          className='booking-button-container'
          href='http://sharpweboffice.com/BenjiHairIKarlstadHKbooking'
          style={{ opacity: this.state.loading ? 0 : 1 }}
        >
          BOKA TID
        </a>
      </div>
    );
  }
}

export default Media;
