import React, { Component, Fragment } from 'react';
import Carousel, { Modal, ModalGateway } from 'react-images';
import '../../css/awards.css';
import img1 from '../../media/awards/1.jpg';
import img2 from '../../media/awards/2.jpg';
import img3 from '../../media/awards/3.jpg';
import img4 from '../../media/awards/4.jpg';

let images = [
  {
    description: 'Nominerad till Nordic Young Talent',
    source: img3,
    header: 'Dilan Rachid'
  },
  {
    description: 'Nominerad till Årets Team & Vinst i Årets Image',
    source: img2,
    header: 'Ravin & Sara'
  },
  {
    description: 'Nominerad till Årets Frisör',
    source: img1,
    header: 'Ravin Sandi'
  },
  {
    description: 'Nominerad till Årets Frisör',
    source: img4,
    header: 'Ravin Sandi'
  }
];

export default class Awards extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  state = {
    modalIsOpen: false,
    image: []
  };

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  };
  render() {
    const { modalIsOpen } = this.state;

    return (
      <Fragment>
        <div className='awards-page-container'>
          {images.map(({ description, source, header }) => (
            <div
              key={source}
              onClick={() =>
                this.setState({ modalIsOpen: true, image: [{ source }] })
              }
              className='awards-cell'
            >
              <img
                src={source}
                alt={description}
                components={description}
                className='awards-cell-image'
              />
              <h2 className='awards-cell-header'>{header}</h2>
              <p className='awards-cell-description'>{description}</p>
            </div>
          ))}
        </div>

        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel views={this.state.image} />
            </Modal>
          ) : null}
        </ModalGateway>
      </Fragment>
    );
  }
}
