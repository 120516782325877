import React, { Component } from 'react';
import { connect } from 'react-redux';
import MediaQuery from 'react-responsive';

import '../../css/frontpage.css';
import Media from './Media';
import ReadMore from './ReadMore';
import CollectionShowcase from './CollectionShowcase';

import MobileMedia from './mobile/MobileMedia';
import MobileReadMore from './mobile/MobileReadMore';
import MobileCollectionShowcase from './mobile/MobileCollectionShowcase';
import InstagramFeed from './InstagramFeed';
import MobileInstagramFeed from './mobile/MobileInstagramFeed';

class FrontPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <div>
        <MediaQuery query='(min-width: 981px)'>
          <Media />
          <ReadMore />
          <CollectionShowcase />
        </MediaQuery>
        <MediaQuery query='(max-width: 980px)'>
          <MobileMedia />

          <MobileReadMore />
          <MobileCollectionShowcase />
        </MediaQuery>
      </div>
    );
  }
}

function mapStateToProps({ general }) {
  return { general };
}

export default connect(mapStateToProps, null)(FrontPage);
