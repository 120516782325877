import React, { Component } from 'react';
import { CircleLoader } from 'react-spinners';

import '../../css/about.css';

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allEmployees: [],
      loaded: {}
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    fetch('/api/booking/employees/list')
      .then(response => response.json())
      .then(data => this.setState({ allEmployees: data }));
  }

  handlePremiumImageLoad = id => {
    this.setState({
      loaded: { ...this.state.loaded, [id]: true }
    });
  };

  handleMegastoreImageLoad = id => {
    this.setState({
      megastoreLoaded: { ...this.state.megastoreLoaded, [id]: true }
    });
  };

  render() {
    return (
      <div>
        <h2 className='decorated premium'>
          <span>VÅRA FRISÖRER</span>
        </h2>
        <div className='profiles-container'>
          {this.state.allEmployees.map(employee => {
            return (
              <div key={employee.id} className='profile-container'>
                <div className='profile-overlay' onClick={null}>
                  <div className='profile-overlay-content'>
                    <p className='profile-overlay-name-header'>
                      <span className='profile-overlay-name'>
                        {employee.name}
                      </span>
                    </p>

                    {employee.title.map(title => {
                      return (
                        <p className='profile-overlay-title-header' key={title}>
                          <span className='profile-overlay-title'>{title}</span>
                        </p>
                      );
                    })}
                    <div className='profile-divider' />
                    {employee.awards.map(award => {
                      return (
                        <p className='profile-overlay-award-header' key={award}>
                          <span className='profile-overlay-award'>{award}</span>
                        </p>
                      );
                    })}
                    <h4 className='profile-name'>
                      {employee.name}
                      <p className='profile-read-more'>Läs mer</p>
                    </h4>
                  </div>
                </div>
                <img
                  className='profile-image'
                  src={employee.picture}
                  alt=''
                  onLoad={() => this.handlePremiumImageLoad(employee.id)}
                  style={{
                    visibility: this.state.loaded[employee.id]
                      ? 'visible'
                      : 'hidden',
                    opacity: this.state.loaded[employee.id] ? 1 : 0
                  }}
                />
                <div
                  style={{
                    width: '22vw',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    position: 'absolute',
                    visibility: !this.state.loaded[employee.id]
                      ? 'visible'
                      : 'hidden'
                  }}
                >
                  <CircleLoader
                    sizeUnit={'px'}
                    size={40}
                    color={'#000000'}
                    loading={!this.state.loaded[employee.id]}
                    style={{ marginLeft: 'auto', marginRight: 'auto' }}
                  />
                </div>
              </div>
            );
          })}
        </div>

        <div
          className='about-divider'
          style={{ marginTop: '6vw', height: '10vw' }}
        />
        <h2 className='about-quote'>"Creativity is intelligence having fun"</h2>
        <p className='about-info-text'>
          Vi på Hair office eftersträvar ständig utveckling och brinner för att
          skräddarsy en look utefter dina önskemål, vi erbjuder alltid de
          senaste trenderna. Hos oss får du alltid tips och råd tillsammans med
          en noggrann konsultation för att på bästa sätt uppnå det du önskar.
          Love is in the hair.
        </p>
        <p className='about-greeting-text'>Varmt välkommen!</p>
      </div>
    );
  }
}

export default About;
