import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MobileReadMore extends Component {
  render() {
    return (
      <Link to='/about' className='readmore-container'>
        <div className='readmore-header-container'>
          <div>
            <h4 className='readmore-header-text'>
              Vi på Hair office eftersträvar ständig utveckling och brinner för
              att skräddarsy en look utefter dina önskemål, vi erbjuder alltid
              de senaste trenderna. Hos oss får du alltid tips och råd
              tillsammans med en noggrann konsultation för att på bästa sätt
              uppnå det du önskar.
              <br />
              Love is in the hair.
            </h4>
            <h4 className='readmore-awards-text'>
              <span className='readmore-awards-header-text'>
                Nominerad till
              </span>
              <br />
              <br />
              Årets team x2
              <br />
              Årets frisör x2
              <br />
              Årets instagramfrisör x1
              <br />
              Nordic Young Talent of The Year
              <br />
              <br />
              <span className='readmore-awards-header-text'>
                Andra utmärkelser
              </span>
              <br />
              <br />
              Årets image x1
              <br />
              Trendvision brons x1
              <br />
            </h4>
            <p className='readmore-link-text-mobile'>Läs mer</p>
          </div>
        </div>
      </Link>
    );
  }
}

export default MobileReadMore;
