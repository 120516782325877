import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';

import { Link } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import HamburgerMenu from 'react-hamburger-menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import logo from '../../media/logoblack.svg';

import '../../css/navbarmobile.css';

class MobileNavbar extends Component {
  constructor(props) {
    super(props);
    this.state = { open: false };
  }

  handleClick() {
    this.setState({
      open: !this.state.open
    });
  }

  render() {
    return (
      <Fragment>
        <div style={{ width: '100%', height: '70px' }} />
        <Menu
          right
          isOpen={this.state.open}
          customBurgerIcon={false}
          customCrossIcon={false}
        >
          <Link className='menu-item' to='/' onClick={() => this.handleClick()}>
            Hem
          </Link>
          <a
            className='menu-item'
            href='http://sharpweboffice.com/BenjiHairIKarlstadHKbooking'
            onClick={() => this.handleClick()}
          >
            Boka
          </a>
          <Link
            className='menu-item'
            to='/about'
            onClick={() => this.handleClick()}
          >
            Våra Frisörer
          </Link>
          <Link
            className='menu-item'
            to='/collections'
            onClick={() => this.handleClick()}
          >
            Kollektioner
          </Link>

          <Link
            className='menu-item'
            to='/awards'
            onClick={() => this.handleClick()}
          >
            Utmärkelser
          </Link>

          <div
            style={{
              position: 'absolute',
              bottom: '20px',
              right: 0,
              left: 0,
              margin: 'auto'
            }}
          >
            <a
              href='https://www.facebook.com/hairofficepremium/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: 'black' }}
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                size='2x'
                transform='shrink-6 up-0.2'
              />
            </a>
            <a
              href='https://www.instagram.com/hairoffice.karlstad/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: 'black' }}
            >
              <FontAwesomeIcon
                icon={faInstagram}
                size='2x'
                transform='shrink-4'
                style={{ marginLeft: '10px' }}
              />
            </a>
          </div>
        </Menu>
        <div className='admin-header'>
          <img
            src={logo}
            alt=''
            className='logo-mobile'
            onClick={() => this.props.history.push('/')}
          />
        </div>
        <div
          style={{ position: 'fixed', zIndex: 1110, right: '5vw', top: '21px' }}
        >
          <HamburgerMenu
            isOpen={this.state.open}
            menuClicked={() => this.handleClick()}
            width={30}
            height={27}
            strokeWidth={1}
            rotate={0}
            color='black'
            borderRadius={0}
            animationDuration={0.5}
          />
        </div>
      </Fragment>
    );
  }
}

export default withRouter(MobileNavbar);
