import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CollectionShowcase extends Component {
  render() {
    return (
      <div className='collection-container'>
        <div className='collection-header-container'>
          <h4 className='collection-header-text'>Senaste kollektionen</h4>
          <p className='collection-info-text'> Simplicity</p>
        </div>
        <div className='collection-link-container'>
          <Link to='/collections' className='collection-link-text'>
            Se alla kollektioner här
          </Link>
        </div>
        <img
          src='https://hairoffice.s3.eu-north-1.amazonaws.com/simplicity/3.jpg'
          className='collection-first-image'
          alt=''
        />
        <img
          src='https://hairoffice.s3.eu-north-1.amazonaws.com/simplicity/1.jpg'
          className='collection-second-image'
          alt=''
        />
        <img
          src='https://hairoffice.s3.eu-north-1.amazonaws.com/simplicity/6.jpg'
          className='collection-third-image'
          alt=''
        />
      </div>
    );
  }
}

export default CollectionShowcase;
