import React, { Component } from 'react';
import premium from '../../media/premiumsalong.jpg';
import megastore from '../../media/torggatan.jpeg';

export default class Booking extends Component {
  render() {
    return (
      <div className='booking-container'>
        <a
          className='booking-item-container'
          style={{ backgroundImage: `url(${premium})` }}
          href='http://sharpweboffice.com/bezattohairhkbooking'
        >
          <div className='booking-item-overlay' />
          <h4 className='booking-item-header-text'>Hairoffice Premium</h4>
        </a>
        <a
          className='booking-item-container'
          style={{ backgroundImage: `url(${megastore})` }}
          href='http://sharpweboffice.com/BenjiHairIKarlstadHKbooking'
        >
          <div className='booking-item-overlay' />
          <h4 className='booking-item-header-text'>Hairoffice Megastore</h4>
        </a>
      </div>
    );
  }
}
