import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Headroom from 'react-headroom';
import MediaQuery from 'react-responsive';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Navbar from './navbar/Navbar';
import Awards from './awards/Awards';
import Booking from './booking/Booking';
import Footer from './Footer';
import MobileNavbar from './navbar/MobileNavbar';
import FrontPage from './frontpage/FrontPage';
import About from './about/About';
import Collections from './collections/Collections';
import * as actions from '../actions';

import '../css/app.css';

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path='/admin'>
          <div />
        </Route>
        <Route path='/'>
          <Fragment>
            <MediaQuery minWidth={981}>
              <Headroom>
                <Navbar />
              </Headroom>
            </MediaQuery>
            <MediaQuery maxWidth={981}>
              <MobileNavbar />
            </MediaQuery>
            <Route
              render={({ location }) => (
                <TransitionGroup>
                  <CSSTransition
                    key={location.key}
                    timeout={200}
                    classNames='fade'
                  >
                    <Fragment>
                      <Switch>
                        <Route exact path='/' render={() => <FrontPage />} />
                        <Route exact path='/about' render={() => <About />} />
                        <Route
                          exact
                          path='/collections'
                          render={() => <Collections />}
                        />
                        <Route exact path='/awards' render={() => <Awards />} />
                      </Switch>
                      <Footer />
                    </Fragment>
                  </CSSTransition>
                </TransitionGroup>
              )}
            />
          </Fragment>
        </Route>
      </Switch>
    );
  }
}

function mapStateToProps({ general }) {
  return { general };
}

export default withRouter(connect(mapStateToProps, actions)(App));
