import React, { Component } from 'react';

import CollectionLeft from './CollectionLeft.js';
import CollectionRight from './CollectionRight.js';

import '../../css/collections.css';
let count = 0;
class Collections extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collections: [],
      lightboxIsOpen: false,
      currentImage: 0
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    fetch('/api/collections')
      .then(response => response.json())
      .then(data => this.setState({ collections: data }));
  }

  isOdd = num => {
    console.log(num);
    return num % 2 === 1;
  };

  render() {
    return (
      <div>
        {this.state.collections.map(collection => {
          count++;
          if (this.isOdd(count)) {
            return (
              <CollectionLeft
                key={collection.id}
                title={collection.title}
                description={collection.description}
                data={collection.data}
              />
            );
          } else {
            return (
              <CollectionRight
                key={collection.id}
                title={collection.title}
                description={collection.description}
                data={collection.data}
              />
            );
          }
        })}
      </div>
    );
  }
}

export default Collections;
