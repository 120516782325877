import {
  UPDATE_STORE,
  UPDATE_CHOSEN_TREATMENTS,
  UPDATE_DURATION,
  UPDATE_CHOSEN_EMPLOYEE,
  UPDATE_CHOSEN_DATE,
  UPDATE_CHOSEN_TIME_ID,
  FETCH_PREMIUM_BUSINESS_HOURS
} from './types';

export const updateStore = store => dispatch => {
  dispatch({
    type: UPDATE_STORE,
    payload: store
  });
  return Promise.resolve();
};

export const updateChosenTimeId = id => dispatch => {
  dispatch({
    type: UPDATE_CHOSEN_TIME_ID,
    payload: id
  });
  return Promise.resolve();
};

export const updateDuration = duration => dispatch => {
  dispatch({
    type: UPDATE_DURATION,
    payload: duration
  });
  return Promise.resolve();
};

export const updateChosenTreatments = treatmentId => dispatch => {
  dispatch({
    type: UPDATE_CHOSEN_TREATMENTS,
    payload: treatmentId
  });
  return Promise.resolve();
};

export const updateChosenEmployee = employeeId => dispatch => {
  dispatch({
    type: UPDATE_CHOSEN_EMPLOYEE,
    payload: employeeId
  });
  return Promise.resolve();
};

export const updateChosenDate = date => dispatch => {
  dispatch({
    type: UPDATE_CHOSEN_DATE,
    payload: date
  });
  return Promise.resolve();
};

export const fetchPremiumBusinessHours = date => async dispatch => {
  const rawResponse = await fetch('/api/premium/booking/businesshours/find', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ date: date })
  });
  const businessHours = await rawResponse.json();

  dispatch({
    type: FETCH_PREMIUM_BUSINESS_HOURS,
    payload: businessHours
  });
  return Promise.resolve();
};
