import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import '../../css/navbar.css';
import logo from '../../media/logoblack.svg';

class Navbar extends Component {
  render() {
    return (
      <div className='navbar'>
        <div className='navbar-collection-left'>
          <Link className='navbar-item' to='/'>
            <span className='navbar-text'>STARTSIDA</span>
          </Link>
          <a className='navbar-item' href='http://sharpweboffice.com/BenjiHairIKarlstadHKbooking'>
            <span className='navbar-text'>BOKA TID</span>
          </a>
        </div>
        <Link to='/' className='navbar-logo-item'>
          <img className='navbar-logo' src={logo} alt='' />
        </Link>
        <div className='navbar-collection-right'>
          <Link className='navbar-item' to='/about'>
            <span className='navbar-text'>FRISÖRER</span>
          </Link>
          <Link className='navbar-item' to='/collections'>
            <span className='navbar-text'>KOLLEKTIONER</span>
          </Link>
          <Link className='navbar-item' to='/awards'>
            <span className='navbar-text'>UTMÄRKELSER</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default Navbar;
