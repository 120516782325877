import React, { Component } from 'react';

import Slider from 'react-slick';

class MobileInstagramFeed extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = { pictures: [], profilePicture: null, profileUsername: null };
  }
  componentDidMount() {
    this._isMounted = true;
    fetch('/api/instagram/posts')
      .then(results => {
        return results.json();
      })
      .then(data => {
        if (this._isMounted) {
          let profilePicture =
            'https://scontent-arn2-1.cdninstagram.com/v/t51.2885-19/s320x320/50226254_347947019141799_257356497665654784_n.jpg?tp=1&_nc_ht=scontent-arn2-1.cdninstagram.com&_nc_ohc=G4XCExCP0b4AX_Cuf9l&edm=ABfd0MgAAAAA&ccb=7-4&oh=1f7d083f52fa7c9f8c97d9d4efba29c0&oe=60A14745&_nc_sid=7bff83';
          let profileUsername = 'hairofficekarlstad';
          let pictures = data.map(data => {
            return (
              <a
                href={data.permalink}
                key={data._id}
                target='_blank'
                rel='noopener noreferrer'
              >
                <div
                  className='instagram-image-slider'
                  style={{
                    backgroundImage: `url(${data.media_url}`
                  }}
                  alt=''
                />
              </a>
            );
          });
          this.setState({
            pictures: pictures,
            profilePicture: profilePicture,
            profileUsername: profileUsername
          });
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false
    };
    return (
      <div className='instagram-container'>
        <a
          href={`http://instagram.com/${this.state.profileUsername}`}
          className='instagram-profile-link'
          target='_blank'
          rel='noopener noreferrer'
        >
          <div className='instagram-profile-container'>
            <img
              src={this.state.profilePicture}
              className='instagram-profile-picture'
              alt=''
            />

            <h1 className='instagram-profile-username'>
              @{this.state.profileUsername}
            </h1>
          </div>
        </a>
        <div>
          <Slider {...settings}>{this.state.pictures}</Slider>
        </div>
      </div>
    );
  }
}

export default MobileInstagramFeed;
