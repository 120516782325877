import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons';

import '../css/footer.css';

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div
          style={{
            backgroundColor: 'white',
            paddingTop: '50px'
          }}
        >
          <div className='footer-divider' />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexWrap: 'wrap'
            }}
          >
            <div className='footer-item-container'>
              <h4 className='footer-subtitle'>Öppettider</h4>
              <p className='footer-text'>Måndag - Fredag: 9 - 18</p>
              <p className='footer-text'>Lördag: 10 - 15</p>
              <p className='footer-text'>Söndag: Stängt</p>
            </div>
            <div className='footer-item-container'>
              <a
                href='https://www.google.se/maps/place/Västra+Torggatan+11,+652+25+Karlstad/@59.3802755,13.4992859,17z/data=!3m1!4b1!4m5!3m4!1s0x465cb3d6b83a719b:0x16aacdc290df1544!8m2!3d59.3802755!4d13.5014799'
                target='_blank'
                rel='noopener noreferrer'
                style={{ textDecoration: 'none' }}
              >
                <h4 className='footer-subtitle'>Adress</h4>
                <p className='footer-text'>Västra Torggatan 11</p>
                <p className='footer-text'>652 25 Karlstad</p>
              </a>
            </div>
            <div className='footer-item-container'>
              <h4 className='footer-subtitle'>Kontakt</h4>
              <a href='tel:054210050' className='footer-text'>
                Tel: 054 - 210050
              </a>
              <br/>
              <br/>
              <a href='tel:054210052' className='footer-text'>
                Tel: 054 - 210052
              </a>
            </div>
          </div>
          <div className='footer-icon-container'>
            <a
              href='https://www.facebook.com/hairofficepremium/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: 'black' }}
            >
              <FontAwesomeIcon
                icon={faFacebookF}
                size='5x'
                transform='shrink-2 up-0.2'
              />
            </a>
            <a
              href='https://www.instagram.com/hairoffice.karlstad/'
              target='_blank'
              rel='noopener noreferrer'
              style={{ color: 'black', marginLeft: '30px' }}
            >
              <FontAwesomeIcon icon={faInstagram} size='5x' />
            </a>
          </div>

          <p className='footer-copyright-text'>©2022 Hair Office</p>
          <br />
        </div>
      </div>
    );
  }
}

export default Footer;
