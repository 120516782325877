import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Media extends Component {
  render() {
    return (
      <div className='media-container'>
        <img
          src='https://hairoffice.s3.eu-north-1.amazonaws.com/simplicity/1.jpg'
          className='media'
          alt=''
        />
        <a className='booking-button-container' href='http://sharpweboffice.com/BenjiHairIKarlstadHKbooking'>
          BOKA TID
        </a>
      </div>
    );
  }
}

export default Media;
