import {
  UPDATE_STORE,
  UPDATE_CHOSEN_TREATMENTS,
  UPDATE_DURATION,
  UPDATE_CHOSEN_EMPLOYEE,
  FETCH_PREMIUM_BUSINESS_HOURS,
  UPDATE_CHOSEN_DATE,
  UPDATE_CHOSEN_TIME_ID
} from '../actions/types';

const initialState = {
  store: null,
  chosenTreatments: [],
  chosenEmployee: 0,
  businessHours: {},
  chosenDate: null,
  chosenTimeId: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CHOSEN_DATE:
      return {
        ...state,
        chosenDate: action.payload
      };
    case UPDATE_CHOSEN_TIME_ID:
      return {
        ...state,
        chosenTimeId: action.payload
      };
    case UPDATE_DURATION:
      return {
        ...state,
        duration: action.payload
      };
    case FETCH_PREMIUM_BUSINESS_HOURS:
      return {
        ...state,
        businessHours: action.payload
      };
    case UPDATE_STORE:
      return {
        ...state,
        store: action.payload
      };
    case UPDATE_CHOSEN_EMPLOYEE:
      if (state.chosenEmployee === action.payload) {
        return {
          ...state,
          chosenEmployee: 0
        };
      } else {
        return {
          ...state,
          chosenEmployee: action.payload
        };
      }

    case UPDATE_CHOSEN_TREATMENTS:
      if (state.chosenTreatments.includes(action.payload)) {
        let newArr = state.chosenTreatments.filter(
          elementId => elementId !== action.payload
        );
        newArr.sort((a, b) => a - b);
        return {
          ...state,
          chosenTreatments: newArr
        };
      } else {
        let newArr = [...state.chosenTreatments, action.payload];
        newArr.sort((a, b) => a - b);
        return {
          ...state,
          chosenTreatments: newArr
        };
      }

    default:
      return state;
  }
}
