import React, { Component } from 'react';

class InstagramFeed extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      profilePicture: null,
      profileUsername: null
    };
  }
  componentDidMount() {
    this._isMounted = true;
    fetch('/api/instagram/posts')
      .then(results => {
        return results.json();
      })
      .then(data => {
        if (this._isMounted) {
          let profilePicture =
            'https://scontent-arn2-1.cdninstagram.com/v/t51.2885-19/s320x320/50226254_347947019141799_257356497665654784_n.jpg?tp=1&_nc_ht=scontent-arn2-1.cdninstagram.com&_nc_ohc=G4XCExCP0b4AX_Cuf9l&edm=ABfd0MgAAAAA&ccb=7-4&oh=1f7d083f52fa7c9f8c97d9d4efba29c0&oe=60A14745&_nc_sid=7bff83';
          let profileUsername = 'hairofficekarlstad';
          let pictures = data.map(data => {
            return (
              <a
                href={data.permalink}
                key={data.id}
                target='_blank'
                rel='noopener noreferrer'
                className='feed-cell'
              >
                <img
                  className='instagram-image'
                  src={data.media_url}
                  alt={data.title}
                />
              </a>
            );
          });

          this.setState({
            pictures: pictures,
            profilePicture: profilePicture,
            profileUsername: profileUsername
          });
        }
      });
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return (
      <div className='instagram-container'>
        <div className='instagram-profile-container'>
          <img
            src={this.state.profilePicture}
            className='instagram-profile-picture'
            alt=''
          />
          <h1 className='instagram-profile-username'>
            @{this.state.profileUsername}
          </h1>
        </div>
        <div className='instagram-feed'>{this.state.pictures}</div>
      </div>
    );
  }
}

export default InstagramFeed;
