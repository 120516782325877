import React, { Component } from 'react';

import Carousel, { Modal, ModalGateway } from 'react-images';

import '../../css/aboutemployee.css';

class CollectionLeft extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      imageLoaded: false,
      images: []
    };
  }
  componentDidMount() {
    this.props.data.map(img => {
      return this.state.images.push({ source: img });
    });
  }

  toggleModal = () => {
    this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
  };

  render() {
    const { modalIsOpen } = this.state;
    return (
      <div>
        <ModalGateway>
          {modalIsOpen ? (
            <Modal onClose={this.toggleModal}>
              <Carousel views={this.state.images} />
            </Modal>
          ) : null}
        </ModalGateway>
        <div
          className='collection-right-container'
          onClick={() => this.setState({ modalIsOpen: true })}
        >
          <img
            className='collection-right-picture'
            src={this.props.data[0]}
            alt=''
          />
          <div className='collection-right-text-container'>
            <h4 className='collection-right-title-text'>{this.props.title}</h4>
            <p className='collection-right-description-text'>
              {this.props.description}
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default CollectionLeft;
