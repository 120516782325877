import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class MobileCollectionShowcase extends Component {
  render() {
    return (
      <Link to='/collections'>
        <div className='collection-container'>
          <img
            src='https://hairoffice.s3.eu-north-1.amazonaws.com/simplicity/5.jpg'
            className='collection-image'
            alt=''
          />
          <div className='collection-header-container'>
            <div>
              <p>
                <span className='collection-header-text'>Kollektioner</span>
              </p>
              <p style={{ marginTop: '7vw' }}>
                <span
                  className='collection-link-text-mobile'
                  style={{ backgroundColor: '#ffffff' }}
                >
                  Varje höst och vår släpper vi nya kollektioner med de senaste
                  och mest framåtsträvande modet.
                </span>
              </p>
              <h4 className='collection-link-text-mobile'>
                Se alla kollektioner här
              </h4>
            </div>
          </div>
        </div>
      </Link>
    );
  }
}

export default MobileCollectionShowcase;
